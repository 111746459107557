import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import Header from "../components/header";
import Createjobdrawer from "../components/createjobdrawer";
import { useParams } from "react-router-dom";

const Createjob = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isEdit, setIsEdit] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);

  // Get the value of 'edit' parameter
  const editFlag = queryParams.get("edit");

  // Get the value of 'JobId' parameter
  const jobId = queryParams.get("JobId");

  return (
    <Box
      display={{ xs: "block", md: "flex" }}
      sx={{
        justifyContent: "space-between",
        gap: 2.5,
        backgroundColor: "rgba(242, 248, 255, 1)",
      }}
      className="pageWrapper"
    >
      <Box
        width={{ xs: "100%", md: "65%" }}
        sx={{ backgroundColor: "white", borderRadius: 4 }}
      >
        <Createjobdrawer editFlag={editFlag} jobId={jobId} />
      </Box>
      <Box
        width={{ xs: "100%", md: "35%" }}
        display={"flex"}
        flexDirection={"column"}
        gap={2.5}
        sx={{ position: "sticky", height: "100%", top: "5%" }}
      >
        <Box sx={{ py: 3, px: 2, backgroundColor: "#FFF3E0", borderRadius: 4 }}>
          <Typography fontSize={"34px"} align="left">
            💡
          </Typography>
          <Typography
            variant="subtitle1"
            fontWeight={600}
            fontSize={"20px"}
            sx={{ pt: 1 }}
          >
            Tips for writing a perfect job description
          </Typography>
          <Typography variant="body1" sx={{ pl: 2, pt: 2.5 }}>
            <ul
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <li>
                <b>Be Specific and Clear: </b>Clearly define the role,
                responsibilities, and expectations. Use straightforward language
                to ensure potential candidates understand the job requirements
                and duties.
              </li>
              <li>
                <b>Highlight Must-Have Qualification: </b>
                Clearly state the essential skills and qualifications required
                for the position, distinguishing between mandatory and preferred
                criteria
              </li>
              <li>
                <b>Describe Your Company Culture: </b>
                Include information about your company's culture and values to
                attract candidates who are a good fit not just for the role, but
                also for the team and organization.
              </li>
            </ul>
          </Typography>
        </Box>
        <Box sx={{ py: 3, px: 2, backgroundColor: "#FFF3E0", borderRadius: 4 }}>
          <Typography fontSize={"34px"} align="left">
            💡
          </Typography>
          <Typography variant="h6" sx={{ pt: 1 }}>
            Why to ask screening questions
          </Typography>
          <Typography variant="body1" sx={{ pl: 2, pt: 2.5 }}>
            <ol
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <li>
                <b>Efficiency in Filtering:</b> Screening questions help quickly
                identify candidates who meet essential criteria, saving time by
                filtering out those who don't meet the basic job requirements.
              </li>
              <li>
                <b>Quality of Candidates:</b> They ensure a higher quality of
                applications by deterring unqualified candidates from applying
                and encouraging those who are genuinely fit for the role to
                proceed with their applications.
              </li>
            </ol>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Createjob;
