import React, { useEffect,useState } from "react";
import Cookies from "js-cookie";
import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { TramSharp } from "@mui/icons-material";

const Companyselection = ({
  company,
  // setCompany,
  selectedCompanies,
   setSelectedCompanies,
  companyRadioValue,
  // setCompanyRadioValue,
  handleCompanyChange,
  handleCompanyRadioChange,
  handleDeleteCompany,
  handleCompanySelectionError,
  companySelectError,
  // selectedBranches,
  // setSelectedBranches,
  // selectedAdmin,
  // isCompanyTab,
  // setIsCompanyTab
  //defaultUnderAssignedComp
}) => {


  return (
    <>
      <Box pb={1}>
        <Box display={"flex"} pb={1}>
          <Typography
            width={"30%"}
            variant="body1"
            fontWeight={500}
            sx={{ paddingTop: "9px" }}
          >
            Company
          </Typography>
          <Box sx={{ display: "flex", width: "100%" }}>
            <FormControl sx={{ width: "50%" }}>
              <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={companyRadioValue}
                onChange={handleCompanyRadioChange}
                sx={{ justifyContent: "end" }}
              >
                <FormControlLabel
                  value="all"
                  control={<Radio />}
                  label="All"
                  sx={{ color: "gray" }}
                />
                <FormControlLabel
                  value="select"
                  control={<Radio />}
                  label="Select"
                  sx={{ color: "gray" }}
                />
              </RadioGroup>
            </FormControl>
            {companyRadioValue === "select" && (
              <Box sx={{ width: "50%" }}>
                <FormControl fullWidth error={companySelectError}>
                  <Select
                    displayEmpty
                    value={selectedCompanies}
                    renderValue={(selected) => (
                      <Typography>
                        Selected{" "}
                        {selected?.length > 0 ? `(${selected?.length})` : ""}
                      </Typography>
                    )}
                    multiple
                    size="small"
                    inputProps={{ "aria-label": "Without label" }}
                    input={<OutlinedInput />}
                    onChange={handleCompanyChange}
                    onBlur={handleCompanySelectionError}
                    MenuProps={{ PaperProps: { style: { maxHeight: 250 } } }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#0096FF",
                        },
                        "&:hover fieldset": {
                          borderColor: "#0096FF",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#0096FF",
                        },
                      },
                    }}
                  >
                    {company.map((item) => (
                      <MenuItem
                        key={item.id}
                        sx={{ textTransform: "capitalize" }}
                        value={item}
                        disabled={selectedCompanies
                          .map((company) => company.id)
                          .includes(item.id)}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {companySelectError && (
                    <FormHelperText>
                      Please select at least one company.
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
            )}
          </Box>
        </Box>
        {selectedCompanies.length > 0 && companyRadioValue === "select" && (
          <Box>
            <Box
              border={1}
              p={1.5}
              borderRadius={1.5}
              borderColor={"gray"}
              width={"65%"}
              marginLeft={"auto"}
            >
              <Box
                display="flex"
                flexWrap="wrap"
                justifyContent={"start"}
                alignContent={"start"}
                alignItems={"start"}
                gap={1}
              >
                {selectedCompanies?.map((company, index) => (
                  <Chip
                    key={index}
                    label={company.name}
                    color="primary"
                    onDelete={() => handleDeleteCompany(company)}
                    sx={{
                      backgroundColor: "rgba(130, 130, 130, 1)",
                      borderRadius: 2,
                    }}
                  />
                ))}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Companyselection;
