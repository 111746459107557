import React, { useEffect, useState, useRef } from "react";

import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Autocomplete,
  TextField,
  Divider,
  FormHelperText,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import MenuPermission from "./menuPermission";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";
import { useFetcher, useLocation } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
const PermissionUI = ({
  role,
  setRole,
  selectedAdmin,
  setSelectedAdmin,
  MenuPermissionerror,
  setMenuPermissionerror,
  assignedUserData,
  userCompanyList,
  setSelectedCompanies,
  setDefaultUnderAssignedComp,
  selectedCompanyAdmin,
  setSelectedCompanyAdmin,
  defaultSelectedCompanies,
  setUpdateAssignedUserState,
  defaultSelectedAdminRef,
  currentRoleType,
}) => {
  const [roleList, setRoleList] = useState([]);
  const [authorityList, setAuthorityList] = useState([]);
  const [list, setList] = useState([]);

  const [assignedUserAuthorityLists, setassignedUserAuthorityLists] = useState(
    {}
  ); // Store authority list per user
  const [selectedAssignedAdmins, setSelectedAssignedAdmins] = useState({}); // Store selected admin per user
  const [openPopup, setOpenPopup] = useState(false);
  const [userRoles, setUserRoles] = useState({});
  const [tempRole, setTempRole] = useState(null);
  const [step, setStep] = useState(1); // 1 for users, 2 for companies

  const [defaultSelectedAdmin, setDefaultSelectedAdmin] =
    useState(selectedAdmin);

  const [defaultselectedCompanyAdmin, setdefaultSelectedCompanyAdmin] =
    useState({});

  const [isAssigneeSelected, setIsAssigneeSelected] = useState(true);

  const loginUser_role = useSelector((state) => state.user.role);

  const fetchAssignedChildAuthority = async (roleId, userId) => {
    try {
      const response = await axios.post(
        "/child-users",
        { user_role_id: roleId },
        {
          headers: {
            authorization:
              Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
          },
        }
      );

      if (response.data.status === 200) {
        const list = response.data.message;

        setassignedUserAuthorityLists((prev) => ({
          ...prev,
          [userId]: list,
        }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    let isAssigneAssigned = true;
    Object.values(selectedCompanyAdmin).map((entry) => {
      if (entry && !(Object.keys(entry).length > 0)) {
        isAssigneAssigned = false;
        return;
      }
    });
    setIsAssigneeSelected(isAssigneAssigned);
  }, [selectedCompanyAdmin]);

  useEffect(() => {
    let isAssigneAssigned = true;
    Object.values(selectedAssignedAdmins).map((entry) => {
      if (entry && !(Object.keys(entry).length > 0)) {
        isAssigneAssigned = false;
        return;
      }
    });
    setIsAssigneeSelected(isAssigneAssigned);
  }, [selectedAssignedAdmins]);

  // Fetch authority lists for all users when popup opens
  useEffect(() => {
    if (openPopup) {
      console.log("role: ", role);

      let isAssigneAssigned = true;
      Object.values(selectedCompanyAdmin).map((entry) => {
        if (!(Object.keys(entry).length > 0)) {
          isAssigneAssigned = false;
          return;
        }
      });
      setIsAssigneeSelected(isAssigneAssigned);

      assignedUserData?.forEach((user) => {
        if (user.role_id) {
          fetchAssignedChildAuthority(user.role_id, user.user_id);
        }
      });
    }
  }, [openPopup, assignedUserData]);

  useEffect(
    () => setSelectedCompanies(defaultSelectedCompanies),
    [defaultSelectedCompanies]
  );
  //for the edit user disabled the fields temporary for now
  const [isEditingDisabled, setIsEditingDisabled] = useState(false); //for the e dit user
  const location = useLocation();
  // Check if user_id is in the query parameters
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get("user_id");
    if (userId && loginUser_role.toUpperCase() === "RECRUITER") {
      setIsEditingDisabled(true);
    } else {
      setIsEditingDisabled(false);
    }
  }, [location.search]);

  // const defaultSelectedAdminRef = useRef(selectedAdmin);
  const fetchRole = async () => {
    try {
      const response = await axios.get("/roles", {
        headers: {
          authorization:
            Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
        },
      });
      if (response.data.status === 200) {
        setRoleList(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchChildAuthority = async () => {
    try {
      const response = await axios.post(
        "/child-users",
        { user_role_id: role.id },
        {
          headers: {
            authorization:
              Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
          },
        }
      );

      if (response.data.status === 200) {
        let list = response.data.message;

        if (selectedAdmin && Object.keys(selectedAdmin).length > 0) {
          list = [
            {
              id: selectedAdmin.user_id,
              name: selectedAdmin.username,
            },
            ...list,
          ];
        }

        setList(list);

        const options = list?.map((user) => ({
          id: user.id,
          name: user.name,
        }));
        setAuthorityList(options);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchRole();
  }, []);

  useEffect(() => {
    if (role.name) {
      const delay = 400;
      const timer = setTimeout(() => {
        fetchChildAuthority();
      }, delay);

      return () => clearTimeout(timer);
    }
  }, [role]);

  // Initialize default names  for each assigned user and company
  useEffect(() => {
    if (assignedUserData?.length > 0) {
      setSelectedAssignedAdmins((prev) => {
        const updatedAdmins = { ...prev };
        assignedUserData.forEach((user) => {
          if (!updatedAdmins[user.user_id]) {
            updatedAdmins[user.user_id] = selectedAdmin?.username;
          }
        });
        return updatedAdmins;
      });
    }
  }, [assignedUserData]);

  useEffect(() => {
    if (userCompanyList?.length > 0) {
      var selectedCompanyAdminValue = (prev) => {
        const updatedCompanies = { ...prev };
        userCompanyList.forEach((company) => {
          if (!updatedCompanies[company.id]) {
            updatedCompanies[company.id] = {
              id: selectedAdmin?.user_id,
              name: selectedAdmin?.username,
            };
          }
        });
        return updatedCompanies;
      };
      setSelectedCompanyAdmin(selectedCompanyAdminValue);
      setdefaultSelectedCompanyAdmin(selectedCompanyAdminValue);
    }
  }, [userCompanyList]);

  const handleRoleChange = (event) => {
    const selectedRoleId = event.target.value;
    const selectedRole = roleList.find(
      (roleItem) => roleItem.id === selectedRoleId
    );
    // setRole(selectedRole);
    // setSelectedAdmin({});

    // Validate and set error state if necessary
    if (!selectedRole) {
      setMenuPermissionerror({
        ...MenuPermissionerror,
        roleError: true,
      });
    } else {
      setMenuPermissionerror({
        ...MenuPermissionerror,
        roleError: false,
      });
    }

    if (
      assignedUserData.length > 0 ||
      Object.keys(selectedCompanyAdmin).length > 0
    ) {
      setTempRole(selectedRole); // Store role temporarily
      if (currentRoleType.current.id != selectedRole.id) {
        setOpenPopup(true); // Open the confirmation dialog
      } else {
        setRole(selectedRole); // Directly update role if no assigned users
      }
    } else {
      setRole(selectedRole); // Directly update role if no assigned users
    }
  };

  const permissions = {
    Employer: [
      {
        department: "Company",
        add: true,
        edit: true,
        view: true,
        delete: true,
      },
      { department: "Branch", add: true, edit: true, view: true, delete: true },
      { department: "Job", add: true, edit: true, view: true, delete: true },
      {
        department: "Candidate",
        add: true,
        edit: true,
        view: true,
        delete: true,
      },
      { department: "User", add: true, edit: true, view: true, delete: true },
    ],
    Admin: [
      {
        department: "Company",
        add: true,
        edit: true,
        view: true,
        delete: true,
      },
      { department: "Branch", add: true, edit: true, view: true, delete: true },
      { department: "Job", add: true, edit: true, view: true, delete: true },
      {
        department: "Candidate",
        add: true,
        edit: true,
        view: true,
        delete: true,
      },
      { department: "User", add: true, edit: true, view: true, delete: true },
    ],
    "TA Manager": [
      {
        department: "Company",
        add: false,
        edit: false,
        view: true,
        delete: false,
      },
      {
        department: "Branch",
        add: false,
        edit: false,
        view: true,
        delete: false,
      },
      { department: "Job", add: true, edit: true, view: true, delete: true },
      {
        department: "Candidate",
        add: true,
        edit: true,
        view: true,
        delete: true,
      },
      { department: "User", add: true, edit: true, view: true, delete: true },
    ],
    Recruiter: [
      {
        department: "Company",
        add: false,
        edit: false,
        view: true,
        delete: false,
      },
      {
        department: "Branch",
        add: false,
        edit: false,
        view: true,
        delete: false,
      },
      { department: "Job", add: true, edit: true, view: true, delete: false },
      {
        department: "Candidate",
        add: true,
        edit: true,
        view: true,
        delete: false,
      },
      {
        department: "User",
        add: false,
        edit: false,
        view: false,
        delete: false,
      },
    ],
  };

  const handleSelectedAuthority = (value) => {
    const entry = list.find((entry) => entry.name === value);

    if (entry) {
      if (openPopup) {
        setSelectedCompanyAdmin(entry);
      } else {
        setSelectedAdmin(entry);

        setMenuPermissionerror({
          ...MenuPermissionerror,
          adminError: false,
        });
      }
      setSelectedCompanies(defaultSelectedCompanies);
    } else {
      setMenuPermissionerror({
        ...MenuPermissionerror,
        adminError: true,
      });
    }
  };
  const selected = selectedAdmin?.name;

  // const handleRoleChange = (event) => {
  //     const selectedRole = roleList.find((r) => r.id === event.target.value);
  //     setRole(selectedRole);

  //     if (assignedUserData.length > 0) {
  //         setOpenPopup(true);
  //     }
  // };

  const handleUserRoleChange = (userId, newRoleId) => {
    setUserRoles((prev) => ({ ...prev, [userId]: newRoleId }));
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
    setTempRole(null);
  };
  useEffect(() => {
    const assignedUserData = {
      user_id: Object.entries(selectedAssignedAdmins).map(([id, name]) => {
        const assignedList = assignedUserAuthorityLists[id] || [];
        const matchedUser = assignedList.find((user) => user.name === name);

        return {
          id: Number(id),
          assignedUnder_name: name,
          assignedUnder_ID: matchedUser
            ? matchedUser.id
            : selectedAdmin.user_id,
        };
      }),

      assignedCompanies: Object.entries(selectedCompanyAdmin)
        .filter(([_, company]) => company.id !== selectedAdmin.user_id)
        .map(([id, name]) => ({
          id: Number(id),
          assignedUnder_name: name,
          current_userId: selectedAdmin.user_id,
        })),
    };

    setUpdateAssignedUserState(assignedUserData);
  }, [selectedAssignedAdmins, selectedCompanyAdmin]); // Runs when dependencies change

  const handleApply = () => {
    // console.log(Object.values(selectedCompanyAdmin));
    let isAssigneAssigned = true;
    Object.values(selectedCompanyAdmin).map((entry) => {
      if (!(Object.keys(entry).length > 0)) {
        isAssigneAssigned = false;
        return;
      }
    });
    if (isAssigneAssigned) {
      if (tempRole) {
        setRole(tempRole); // Apply the temporary role selection
        setTempRole(null);
      }

      if (!defaultSelectedAdminRef.current) {
        defaultSelectedAdminRef.current = selectedAdmin;
      }
      if (
        JSON.stringify(defaultselectedCompanyAdmin) !==
        JSON.stringify(selectedCompanyAdmin)
      ) {
        const updatedCompanies = Object.entries(selectedCompanyAdmin)
          .filter(([_, name]) => name.id === selectedAdmin.user_id) // Filter matching entries
          .map(([id]) => {
            const company =
              userCompanyList?.find((company) => company.id == id) || {}; // Find matching company

            return {
              id,
              name: company.name || "",
              //  is_branch_managed: company.is_branch_managed ?? null // Include is_branch_managed, fallback to null
            };
          });

        setSelectedCompanies(updatedCompanies);

        // setSelectedCompanies((prev) => ({
        //   ...prev,
        //   [id]: newValue,
        // }))
      }
      setDefaultSelectedAdmin(selectedAdmin);
      setSelectedAdmin({});
      setOpenPopup(false);
    }
  };

  const getFilteredRoles = () => {
    console.log("defaultSelectedAdminRef: ", defaultSelectedAdminRef.current);
    console.log("selectedAdmin123: ", defaultSelectedAdminRef.current);

    if (
      !defaultSelectedAdminRef.current ||
      Object.keys(defaultSelectedAdminRef.current).length == 0
    )
      return roleList; // Show all roles if no selectedAdmin

    const adminRoleMatch =
      defaultSelectedAdminRef.current.username?.match(/\(([^)]+)\)/); // Extracts role inside parentheses
    const adminRole = adminRoleMatch ? adminRoleMatch[1] : null;

    if (!adminRole) return roleList; // If no valid role found, show default list

    // Define role hierarchy (higher roles appear earlier)
    const roleHierarchy = ["Admin", "TA Manager", "Recruiter"];

    // Find the index of the selected admin's role
    const adminRoleIndex = roleHierarchy.indexOf(adminRole);

    // Filter roles that are the same or higher in hierarchy
    return roleList.filter(
      (role) => roleHierarchy.indexOf(role.name) <= adminRoleIndex
    );
  };

  return (
    <Box sx={{ p: 1 }}>
      {/* Role Selection */}
      <Box display="flex" alignItems="center" mb={2}>
        <Typography width="29%" variant="body1" fontWeight={500}>
          Select Role Type
        </Typography>
        <FormControl
          fullWidth
          error={MenuPermissionerror.roleError}
          sx={{ height: "50px" }} // Ensure FormControl height aligns
        >
          <InputLabel
            id="demo-simple-select-label"
            sx={{ top: 0, left: 0, zIndex: 1 }} // Adjust label positioning if needed
          >
            Select Role
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Select Role"
            value={role ? role.id : ""}
            onChange={handleRoleChange}
            sx={{ height: "100%", display: "flex", alignItems: "center" }} // Center text in Select
            disabled={isEditingDisabled}
          >
            {getFilteredRoles().map((roleItem) => (
              <MenuItem
                key={roleItem.id}
                value={roleItem.id}
                sx={{ fontSize: "0.875rem" }}
              >
                {roleItem.name}
              </MenuItem>
            ))}
          </Select>
          {MenuPermissionerror.roleError && (
            <FormHelperText sx={{ margin: 0 }}>
              Please select a role.
            </FormHelperText>
          )}
        </FormControl>
      </Box>

      <Dialog
        open={openPopup}
        onClose={handleClosePopup}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          {step === 1 && assignedUserData.length > 0
            ? "Assigned Users"
            : "Assigned Companies"}
        </DialogTitle>
        <DialogContent>
          {step === 1 && assignedUserData.length > 0 ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Username</TableCell>
                  <TableCell>Assign User Under</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {assignedUserData?.map((user) => (
                  <TableRow key={user.user_id}>
                    <TableCell>{user.user_name}</TableCell>
                    <TableCell>
                      <Autocomplete
                        freeSolo
                        disableClearable
                        id={`autocomplete-user-${user.user_id}`}
                        options={
                          assignedUserAuthorityLists[user.user_id]?.map(
                            (item) => item.name
                          ) || [] // Extract names
                        }
                        value={
                          selectedAssignedAdmins[user.user_id] ||
                          selectedAssignedAdmins.name
                        }
                        onChange={(e, newValue) => {
                          setSelectedAssignedAdmins((prev) => ({
                            ...prev,
                            [user.user_id]: newValue,
                          }));
                        }}
                        onInputChange={(e, newInputValue) => {
                          if (newInputValue === "") {
                            setSelectedAssignedAdmins((prev) => ({
                              ...prev,
                              [user.user_id]: "",
                            }));
                            setIsAssigneeSelected(false);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select"
                            error={
                              selectedAssignedAdmins[user.user_id] ||
                              selectedAssignedAdmins.name
                                ? false
                                : true
                            }
                            helperText={
                              selectedAssignedAdmins[user.user_id] ||
                              selectedAssignedAdmins.name
                                ? ""
                                : "Please select assignee"
                              // !MenuPermissionerror.roleError &&
                              // MenuPermissionerror.adminError
                              //   ? "Please select assign user under."
                              //   : MenuPermissionerror.adminError &&
                              //     MenuPermissionerror.roleError
                              //   ? "Please select role."
                              //   : ""
                            }
                          />
                        )}
                        fullWidth
                        disabled={!user.role_id || isEditingDisabled}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            // Step 2: Assigned Companies View
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Company Name</TableCell>
                  {role.name == "Recruiter" ? (
                    <TableCell>Do you wish to assign company?</TableCell>
                  ) : (
                    <TableCell>Assign Company Under</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {userCompanyList?.map((company) => (
                  <TableRow key={company.id}>
                    <TableCell>{company.name}</TableCell>
                    <TableCell>
                      {role.name == "Recruiter" ? (
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="yes"
                            name="radio-buttons-group"
                            onChange={(e) => {
                              if (e.target.value == "yes") {
                                // console.log(
                                //   "selectedCompanyAdmin: ",
                                //   selectedCompanyAdmin
                                // );
                                setSelectedCompanyAdmin((prevState) => ({
                                  ...prevState,
                                  [company.id]:
                                    authorityList.find(
                                      (item) =>
                                        item.name ===
                                        defaultSelectedAdminRef.current.username
                                    ) || {},
                                }));
                              } else {
                                // console.log(
                                //   "selectedCompanyAdmin: ",
                                //   selectedCompanyAdmin
                                // );
                                setSelectedCompanyAdmin((prevState) => ({
                                  ...prevState,
                                  [company.id]:
                                    authorityList.find(
                                      (item) =>
                                        item.name ===
                                        defaultSelectedAdminRef.current.name
                                    ) || {},
                                }));
                              }
                            }}
                          >
                            <FormControlLabel
                              value="yes"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value="no"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      ) : (
                        <Autocomplete
                          freeSolo
                          disableClearable
                          id={`autocomplete-user-under-${company.id}`}
                          style={{ width: "100%" }}
                          options={
                            authorityList?.map((item) => item.name) || []
                          }
                          value={selectedCompanyAdmin[company.id]?.name || ""}
                          onChange={(e, newValue) => {
                            console.log(
                              "selectedCompanyAdmin: ",
                              selectedCompanyAdmin
                            );
                            console.log("newValue: ", newValue);

                            setSelectedCompanyAdmin((prevState) => ({
                              ...prevState,
                              [company.id]:
                                authorityList.find(
                                  (item) => item.name === newValue
                                ) || {},
                            }));
                          }}
                          onInputChange={(e, newInputValue) => {
                            if (newInputValue === "") {
                              setSelectedCompanyAdmin((prevState) => ({
                                ...prevState,
                                [company.id]: {}, // Clear the selection for this company
                              }));
                              setIsAssigneeSelected(false);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select"
                              error={
                                selectedCompanyAdmin[company.id]?.name
                                  ? false
                                  : true
                              }
                              helperText={
                                selectedCompanyAdmin[company.id]?.name
                                  ? ""
                                  : "Please select assignee"
                                // !MenuPermissionerror.roleError &&
                                // MenuPermissionerror.adminError
                                //   ? "Please select assign user under."
                                //   : MenuPermissionerror.adminError &&
                                //     MenuPermissionerror.roleError
                                //   ? "Please select role."
                                //   : ""
                              }
                              style={{ height: "100%" }}
                            />
                          )}
                          fullWidth
                          disabled={!role || isEditingDisabled}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </DialogContent>
        <DialogActions>
          {step === 2 && assignedUserData.length > 0 ? (
            <Button onClick={() => setStep(1)} color="primary">
              Back
            </Button>
          ) : (
            <Button onClick={handleClosePopup} color="primary">
              Close
            </Button>
          )}
          {step === 1 && assignedUserData.length > 0 ? (
            isAssigneeSelected ? (
              <Button
                variant="contained"
                onClick={() => {
                  let isAssigneAssigned = true;
                  Object.values(selectedAssignedAdmins).map((entry) => {
                    if (!(Object.keys(entry).length > 0)) {
                      isAssigneAssigned = false;
                      return;
                    }
                  });
                  if (isAssigneAssigned) {
                    setStep(2);
                  }
                }}
              >
                Next
              </Button>
            ) : (
              <Button variant="contained" disabled>
                Next
              </Button>
            )
          ) : isAssigneeSelected ? (
            <Button variant="contained" onClick={handleApply}>
              Apply
            </Button>
          ) : (
            <Button variant="contained" disabled>
              Apply
            </Button>
          )}
        </DialogActions>
        {/* <DialogActions>
          {step === 1 ? (
            <>
              <Button onClick={handleClosePopup} color="primary">
                Close
              </Button>
              <Button variant="contained" onClick={() => setStep(2)}>
                Next
              </Button>
            </>
          ) : (
            <>
              <Button onClick={() => setStep(1)} color="primary">
                Back
              </Button>
              <Button variant="contained" onClick={handleApply}>
                Apply
              </Button>
            </>
          )}
        </DialogActions> */}
      </Dialog>

      {/* {/ Permission Table /} */}
      {Object.keys(role).length > 0 && (
        <TableContainer
          sx={{ borderRadius: 2, width: "78%", marginLeft: "auto", mb: 2 }}
        >
          <Table size="small" sx={{ borderCollapse: "separate" }}>
            <TableHead>
              <TableRow
                sx={{ backgroundColor: "primary.main", color: "white" }}
              >
                <TableCell
                  align="center"
                  sx={{
                    borderBottom: "none",
                    borderRight: "2px solid white",
                    color: "#fff",
                  }}
                >
                  Department
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    borderBottom: "none",
                    borderRight: "1px solid white",
                    color: "#fff",
                  }}
                >
                  Add
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    borderBottom: "none",
                    borderRight: "1px solid white",
                    color: "#fff",
                  }}
                >
                  Edit
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    borderBottom: "none",
                    borderRight: "1px solid white",
                    color: "#fff",
                  }}
                >
                  View
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    borderBottom: "none",
                    borderRight: "1px solid white",
                    color: "#fff",
                  }}
                >
                  Delete
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {permissions[role?.name]?.map((perm, index) => (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor: index % 2 === 0 ? "#f9f9f9" : "white",
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell
                    align="center"
                    sx={{ pr: 3, borderRight: "1px solid white" }}
                  >
                    {perm.department}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: perm.add ? "green" : "red" }}
                  >
                    {perm.add ? <CheckIcon /> : <CloseIcon />}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: perm.edit ? "green" : "red" }}
                  >
                    {perm.edit ? <CheckIcon /> : <CloseIcon />}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: perm.view ? "green" : "red" }}
                  >
                    {perm.view ? <CheckIcon /> : <CloseIcon />}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: perm.delete ? "green" : "red" }}
                  >
                    {perm.delete ? <CheckIcon /> : <CloseIcon />}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Divider />

      {/* Radio Group - Assign Under */}
      <Box display="flex" alignItems="center" mt={2} gap={2}>
        <Typography
          width="25%"
          variant="body1"
          fontWeight={500}
          sx={{ whiteSpace: "nowrap", display: "flex", alignItems: "center" }}
          style={{
            marginBottom: MenuPermissionerror.adminError ? "17px" : "0px",
          }}
        >
          Assign User Under
        </Typography>
        <Autocomplete
          freeSolo
          disableClearable
          id="autocomplete-user-under"
          style={{ width: "100%" }}
          options={
            authorityList
              ?.filter(
                (item) =>
                  item.name !== undefined && item.id !== selectedAdmin.user_id
              )
              .map((item) => item.name) || []
          }
          value={selectedAdmin?.name || ""} // Reflect selectedAdmin's name or empty string if none selected
          onChange={(e, newValue) => handleSelectedAuthority(newValue)} // Trigger handleSelectedAuthority when an option is selected
          onInputChange={(e, newInputValue) => {
            if (newInputValue === "") {
              setSelectedAdmin({}); // Clear selectedAdmin when input is cleared
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select"
              error={MenuPermissionerror.adminError}
              helperText={
                !MenuPermissionerror.roleError && MenuPermissionerror.adminError
                  ? "Please select assign user under."
                  : MenuPermissionerror.adminError &&
                    MenuPermissionerror.roleError
                  ? "Please select role."
                  : ""
              }
              style={{ height: "100%" }}
            />
          )}
          fullWidth
          disabled={!role || isEditingDisabled}
        />
      </Box>
    </Box>
  );
};

export default PermissionUI;
