import React, { useEffect, useRef, useState } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import BlotFormatter from "quill-blot-formatter";
import {
  Button,
  Modal,
  Box,
  Typography,
  Snackbar,
  Alert,
  TextField,
  Grid,
  Paper,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material"; // Import necessary MUI components
import axios from "axios";
import Cookies from "js-cookie";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

Quill.register("modules/blotFormatter", BlotFormatter);

// Register alignment format
const Align = Quill.import("attributors/style/align");
Align.whitelist = ["left", "center", "right", "justify"];
Quill.register(Align, true);

const RichTextEditor = () => {
  const editorRef = useRef(); //for quill
  const { id } = useParams();
  const navigate = useNavigate();
  const [htmlContent, setHtmlContent] = useState(""); //html content
  const [isImageModalOpen, setIsImageModalOpen] = useState(false); //image select model on quill
  const [imageFiles, setImageFiles] = useState([]); //uploaded images
  const [uploadedImages, setUploadedImages] = useState([]); //for existing images
  //snackbar message
  const [snackbarData, setSnackbarData] = useState({
    snackbarOpen: false,
    snackbarMessage: "",
    snackbarSeverity: "success",
  });
  //preview dialog box
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  // New state variables for title, short description, targeted keywords, and featured image
  const [blogData, setBlogData] = useState({
    title: "",
    shortDescription: "",
    targetedKeywords: "",
    featuredImage: null,
    featured_image_name: "",
    altTag: "",
    metaTitle: "",
    metaDescription: "",
    content: "",
    loaderBlogs: false,
  });
  const fileInputRef = useRef(null); // Reference to the file input

  //for changing the input value of fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBlogData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  //this function is for adding unique class in html content for styling
  const addUniqueClassToHTML = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    let count = 0;
    doc.body.querySelectorAll("*").forEach((element) => {
      const uniqueClass = `unique-class-${count++}`;
      element.classList.add(uniqueClass);
    });

    return doc.body.innerHTML;
  };

  const initializeQuillEditor = (content) => {
    // Initialize Quill editor
    const quill = new Quill(editorRef.current, {
      theme: "snow",
      modules: {
        toolbar: [
          ["bold", "italic", "underline"],
          [{ header: [1, 2, false] }],
          [{ align: [] }],
          [{ indent: "-1" }],
          [{ indent: "+1" }],
          ["link", "image"],
          [{ list: "ordered" }, { list: "bullet" }],
          ["clean"],
        ],
        blotFormatter: {},
      },
      placeholder: "Type here...",
    });

    // Add image handler
    quill.getModule("toolbar").addHandler("image", openImageModal);

    // Set the editorRef to the Quill instance directly
    editorRef.current = quill;

    // Set default content if provided
    if (content) {
      quill.clipboard.dangerouslyPasteHTML(content); // Set the fetched HTML content
      quill.setContents(quill.clipboard.convert(content), "silent"); // Ensure it is properly set as silent
    }

    quill.on("text-change", () => {
      const html = quill.root.innerHTML;
      const modifiedHTML = addUniqueClassToHTML(html);
      setHtmlContent(modifiedHTML);
    });

    return quill;
  };

  useEffect(() => {
    if (blogData.content) {
      initializeQuillEditor(blogData.content);
    }
  }, [blogData.content]);
  const openImageModal = async () => {
    setIsImageModalOpen(true);
    await fetchUploadedImages();
  };

  // Fetch uploaded images
  const fetchUploadedImages = async () => {
    try {
      const response = await axios.get("/blog-images");
      if (response.data && Array.isArray(response.data.message)) {
        setUploadedImages(response.data.message);
      } else {
        setUploadedImages([]);
      }
    } catch (error) {
      console.error("Error fetching image URLs:", error);
      setUploadedImages([]);
      setSnackbarData({
        ...snackbarData,
        snackbarOpen: true,
        snackbarMessage: "Failed to fetch images.",
        snackbarSeverity: "error",
      });
    }
  };

  // Handle image upload
  const handleImageUpload = async () => {
    const blog_image = new FormData();
    imageFiles.forEach((file) => blog_image.append("blog_image", file));

    try {
      const response = await axios.post("/upload-blog-images", blog_image, {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization:
            Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
        },
      });
      await fetchUploadedImages();
      setImageFiles([]);
      setSnackbarData({
        ...snackbarData,
        snackbarOpen: true,
        snackbarMessage: "Images uploaded successfully!",
        snackbarSeverity: "success",
      });
    } catch (error) {
      console.error("Error uploading images:", error);
      setSnackbarData({
        ...snackbarData,
        snackbarOpen: true,
        snackbarMessage: "Error uploading images.",
        snackbarSeverity: "error",
      });
    }
  };

  // Insert image into quill editor
  const insertImageIntoEditor = (url) => {
    if (editorRef.current) {
      const quillEditor = editorRef.current;
      const range = quillEditor.getSelection();
      if (range) {
        quillEditor.insertEmbed(range.index, "image", url);
        quillEditor.setSelection(range.index + 1);
        setSnackbarData({
          ...snackbarData,
          snackbarOpen: true,
          snackbarMessage: "Image inserted successfully!",
          snackbarSeverity: "success",
        });
      }
      setIsImageModalOpen(false);
      setImageFiles([]);
    } else {
      console.error("Editor reference is not available.");
    }
  };

  //handlefile change in quill editor
  const handleImageFileChange = (e) => {
    const files = Array.from(e.target.files);
    const validImageTypes = [
      "image/webp",
      "image/svg+xml",
      "image/png",
      "image/jpeg",
      "image/jpg",
    ];

    const maxFileSize = 10 * 1024 * 1024; // 10MB in bytes

    const invalidFiles = files.filter(
      (file) => !validImageTypes.includes(file.type) || file.size > maxFileSize // Check file size
    );

    if (invalidFiles.length > 0) {
      setSnackbarData({
        snackbarOpen: true,
        snackbarMessage:
          "Only image files (webp, svg, png, jpg, jpeg) up to 10MB are allowed!",
        snackbarSeverity: "error",
      });
    } else {
      // Ensure that selected images are not duplicates
      const newFiles = files.filter(
        (file) =>
          !imageFiles.some(
            (existingFile) =>
              existingFile.name === file.name && existingFile.size === file.size
          )
      );

      if (newFiles.length > 0) {
        setImageFiles((prevFiles) => [...prevFiles, ...newFiles]);
        setSnackbarData({
          snackbarOpen: true,
          snackbarMessage: "Images selected successfully!",
          snackbarSeverity: "success",
        });
      } else {
        setSnackbarData({
          snackbarOpen: true,
          snackbarMessage: "You have already selected this image!",
          snackbarSeverity: "warning",
        });
      }
    }
  };

  // Handle removing the image
  const handleRemoveImage = (index) => {
    const updatedFiles = imageFiles.filter((_, i) => i !== index);
    setImageFiles(updatedFiles);

    // Manually reset the input value to allow re-selection of the same file
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset input value
    }
  };

  const handleFeaturedImageChange = (e) => {
    const file = e.target.files[0];
    const validImageTypes = [
      "image/webp",
      "image/svg+xml",
      "image/png",
      "image/jpeg",
      "image/jpg",
    ];
    const maxFileSize = 10 * 1024 * 1024; // 10MB

    if (file) {
      if (!validImageTypes.includes(file.type)) {
        setSnackbarData({
          snackbarOpen: true,
          snackbarMessage:
            "Only image files (webp, svg, png, jpg, jpeg) are allowed for the featured image!",
          snackbarSeverity: "error",
        });
      } else if (file.size > maxFileSize) {
        setSnackbarData({
          snackbarOpen: true,
          snackbarMessage: "Featured image cannot exceed 10MB!",
          snackbarSeverity: "error",
        });
      } else {
        setBlogData((prevData) => ({
          ...prevData,
          featuredImage: file,
        }));
        setSnackbarData({
          snackbarOpen: true,
          snackbarMessage: "Featured image selected successfully!",
          snackbarSeverity: "success",
        });
      }
    }
  };
  // Fetch blog data for editing
  const fetchBlogDataForEdit = async () => {
    try {
      const response = await axios.get(`/blog/${id}`);
      const FetchblogData = response.data.message[0];
      // Populate the form with the fetched blog data
      if (response.status === 200) {
        setBlogData({
          title: FetchblogData.title,
          shortDescription: FetchblogData.description,
          targetedKeywords: FetchblogData.keywords,
          featuredImage: FetchblogData.featured_image,
          featured_image_name: FetchblogData.featured_image_name,
          altTag: FetchblogData.alt_text,
          metaTitle: FetchblogData.meta_title,
          metaDescription: FetchblogData.meta_description,
          content: FetchblogData.content,
        });
        setHtmlContent(FetchblogData.content);
      }
    } catch (error) {
      console.error("Error fetching blog data:", error);
      setSnackbarData({
        ...snackbarData,
        snackbarOpen: true,
        snackbarMessage: "Error fetching blog data for editing.",
        snackbarSeverity: "error",
      });
    }
  };

  useEffect(() => {
    if (id) {
      fetchBlogDataForEdit();
    } else {
      initializeQuillEditor();
    }
  }, [id]);

  // Post content to the server
  const handlePostContent = async () => {
    setBlogData((prevData) => ({
      ...prevData,
      loaderBlogs: true,
    }));
    // Validate required fields
    if (
      !blogData.title ||
      !blogData.shortDescription ||
      !blogData.targetedKeywords ||
      !blogData.featuredImage ||
      !blogData.altTag ||
      !blogData.metaTitle ||
      !blogData.metaDescription ||
      !htmlContent
    ) {
      setSnackbarData({
        ...snackbarData,
        snackbarOpen: true,
        snackbarMessage: "All fields are required.",
        snackbarSeverity: "error",
      });
      return; // Stop execution if validation fails
    }

    const formData = new FormData();
    formData.append("title", blogData.title);
    formData.append("description", blogData.shortDescription);
    formData.append("keywords", blogData.targetedKeywords);
    formData.append("content", htmlContent);
    formData.append("alt_text", blogData.altTag);
    formData.append("meta_title", blogData.metaTitle);
    formData.append("meta_description", blogData.metaDescription);
    formData.append("featured_image_name", blogData.featured_image_name);
    if (blogData.featuredImage) {
      formData.append("feature_image", blogData.featuredImage); // Append the featured image if selected
    }
    const api_url = id ? `/edit-blog/${id}` : "/create-blog";
    try {
      const response = await axios.post(api_url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization:
            Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
        },
      });
      if (response) {
        setIsPreviewModalOpen(false);
        setSnackbarData({
          ...snackbarData,
          snackbarOpen: true,
          snackbarMessage: response?.data?.message?.msg,
          snackbarSeverity: "success",
        });
        // Reset form fields
        setHtmlContent("");
        setBlogData({
          title: "",
          shortDescription: "",
          targetedKeywords: "",
          featuredImage: null,
          altTag: "",
          metaTitle: "",
          metaDescription: "",
        });
        setTimeout(() => {
          if (id) {
            navigate(`/blog/details/${id}`);
          } else {
            navigate("/blogs");
          }
        }, 800);
      }
    } catch (error) {
      console.error("Error posting content:", error);
      setBlogData((prevData) => ({
        ...prevData,
        loaderBlogs: false,
      }));
      setSnackbarData({
        ...snackbarData,
        snackbarOpen: true,
        snackbarMessage: error?.response?.data?.error,
        snackbarSeverity: "error",
      });
    }
  };

  //close the snackbar
  const handleCloseSnackbar = () => {
    setSnackbarData({
      ...snackbarData,
      snackbarOpen: false,
    });
  };

  const handleOpenPreviewModal = () => setIsPreviewModalOpen(true);
  const handleClosePreviewModal = () => setIsPreviewModalOpen(false);

  // Function to sanitize and style HTML content
  const sanitizeAndStyleHTML = (htmlContent) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent;
    const images = tempDiv.getElementsByTagName("img");
    for (let img of images) {
      img.style.maxWidth = "100%";
      img.style.height = "auto";
      img.style.objectFit = "cover";
      img.style.borderRadius = "12px";
    }
    return tempDiv.innerHTML;
  };

  const styledContent = sanitizeAndStyleHTML(htmlContent);

  return (
    <Box className="pageWrapper" sx={{ backgroundColor: "#F2F8FF" }}>
      <Box
        sx={{
          padding: "40px 60px",
          borderRadius: "10px",
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          backgroundColor: "white",
          maxWidth: "1200px",
          margin: "auto",
        }}
      >
        {/* <Typography variant="h5" sx={{ fontWeight: "bold", marginBottom: 3 }}>
          Create Blog
        </Typography> */}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Title*"
              variant="outlined"
              placeholder="Enter blog title"
              name="title"
              value={blogData.title}
              onChange={handleInputChange}
              sx={{ backgroundColor: "#fff", borderRadius: "4px" }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Short Description*"
              placeholder="Enter short description"
              variant="outlined"
              name="shortDescription"
              value={blogData.shortDescription}
              onChange={handleInputChange}
              sx={{ backgroundColor: "#fff", borderRadius: "4px" }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Targeted Keywords*"
              placeholder="Enter targeted keywords with comma(,) seperated"
              variant="outlined"
              name="targetedKeywords"
              value={blogData.targetedKeywords}
              onChange={handleInputChange}
              sx={{ backgroundColor: "#fff", borderRadius: "4px" }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Alt Text for Images*"
              placeholder="Enter alt text in images sequence with comma(,) seperated"
              variant="outlined"
              name="altTag"
              value={blogData.altTag}
              onChange={handleInputChange}
              sx={{ backgroundColor: "#fff", borderRadius: "4px" }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Meta Title*"
              placeholder="Enter meta title of blog"
              variant="outlined"
              name="metaTitle"
              value={blogData.metaTitle}
              onChange={handleInputChange}
              sx={{ backgroundColor: "#fff", borderRadius: "4px" }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Meta Description*"
              placeholder="Enter meta description of blog"
              variant="outlined"
              name="metaDescription"
              value={blogData.metaDescription}
              onChange={handleInputChange}
              sx={{ backgroundColor: "#fff", borderRadius: "4px" }}
            />
          </Grid>
          <Grid item xs={12}>
            <input
              accept="image/*"
              type="file"
              onChange={handleFeaturedImageChange}
              style={{ display: "none" }}
              id="featured-image-upload"
            />
            <label htmlFor="featured-image-upload">
              <Button
                variant="outlined"
                color="primary"
                component="span"
                textTransform="capitalize"
                sx={{
                  borderRadius: "4px",
                  padding: "10px 20px",
                  fontWeight: "bold",
                }}
              >
                {blogData.featuredImage
                  ? "Change Featured Image"
                  : "Upload Featured Image"}
              </Button>
            </label>
            {blogData.featuredImage && (
              <Paper
                variant="outlined"
                sx={{
                  padding: 2,
                  marginTop: 2,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="body1" sx={{ marginRight: 2 }}>
                  {blogData.featuredImage.name || "Featured Image"}
                </Typography>
                <img
                  src={
                    blogData.featuredImage instanceof File
                      ? URL.createObjectURL(blogData.featuredImage)
                      : blogData.featuredImage
                  }
                  alt="Featured Preview"
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                    borderRadius: "8px",
                  }}
                />
              </Paper>
            )}
          </Grid>
        </Grid>

        <Typography
          variant="h6"
          sx={{
            fontWeight: "400",
            marginBottom: 2,
            marginTop: 3,
            textTransform: "capitalize",
          }}
        >
          Blog Description
        </Typography>
        <div style={{ width: "100%", marginTop: "20px" }}>
          <div
            ref={editorRef}
            style={{
              height: "400px",
              border: "1px solid #ccc",
              overflow: "auto",
              width: "100%",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "40px 0px 0px 0px",
          }}
        >
          <Button
            variant="contained"
            onClick={handleOpenPreviewModal}
            disabled={
              !blogData.title ||
              !blogData.shortDescription ||
              !blogData.targetedKeywords ||
              !blogData.altTag ||
              !blogData.metaTitle ||
              !blogData.metaDescription ||
              !htmlContent ||
              !blogData.featuredImage
            }
          >
            {id ? "Preview Edited Content" : "Preview"}
          </Button>
        </div>
      </Box>

      {/* Blog Preview Modal */}
      <Dialog
        open={isPreviewModalOpen}
        onClose={handleClosePreviewModal}
        fullWidth
        maxWidth="md"
      >
        <Typography variant="h5" align="center" sx={{ marginTop: 2 }}>
          Blog Preview
        </Typography>
        <DialogContent dividers>
          <Box sx={{ maxHeight: "400px", overflowY: "auto", padding: 2 }}>
            {/* Display the blog title */}
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                fontSize: { xs: "24px", md: "28px", lg: "28px" },
                fontWeight: "700",
              }}
            >
              {blogData.title}
            </Typography>

            {/* Display the featured image if available */}
            {blogData.featuredImage && (
              <Box
                sx={{
                  display: "flex",
                  marginBottom: 2,
                }}
              >
                <img
                  src={
                    blogData.featuredImage instanceof File
                      ? URL.createObjectURL(blogData.featuredImage)
                      : blogData.featuredImage
                  }
                  alt="Featured"
                  style={{
                    width: "100%",
                    height: "auto",
                    borderRadius: "8px",
                  }}
                />
              </Box>
            )}

            {/* Display the short description */}
            <Typography
              variant="body1"
              sx={{
                fontWeight: "400",
                lineHeight: "38px",
                textAlign: "justify",
                marginBottom: 2,
              }}
            >
              {blogData.shortDescription}
            </Typography>

            {/* Display the main blog content */}
            <div
              style={{ textAlign: "-moz-initial" }}
              dangerouslySetInnerHTML={{ __html: styledContent }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClosePreviewModal}
            sx={{ textTransform: "capitalize" }}
            variant="outlined"
          >
            Edit
          </Button>
          <Button
            onClick={handlePostContent}
            variant="contained"
            color="primary"
            disabled={blogData.loaderBlogs}
            sx={{ textTransform: "capitalize" }}
          >
            {blogData.loaderBlogs ? "Posting..." : "Confirm and Post"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Image Modal */}
      <Modal
        open={isImageModalOpen}
        onClose={() => {
          setIsImageModalOpen(false);
          setImageFiles([]);
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: 4,
            maxWidth: 600,
            backgroundColor: "white",
            borderRadius: "8px",
            boxShadow: 3,
          }}
        >
          {/* Close Button */}
          <IconButton
            onClick={() => {
              setIsImageModalOpen(false);
              setImageFiles([]);
            }}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: "#888",
            }}
          >
            <CloseIcon />
          </IconButton>

          <Typography variant="h6" align="center">
            Select Images
          </Typography>

          <input
            accept="image/*"
            type="file"
            multiple
            onChange={handleImageFileChange}
            style={{ display: "none" }}
            id="new-image-upload"
            ref={fileInputRef} // Set ref to file input
          />

          {imageFiles.length > 0 && (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
                maxHeight: "200px",
                overflowY: "auto",
                justifyContent: "center",
                marginTop: 10,
                position: "relative",
              }}
            >
              {imageFiles.map((file, index) => (
                <div key={index} style={{ position: "relative" }}>
                  {/* Uploaded image preview */}
                  <img
                    src={
                      file && file instanceof File
                        ? URL.createObjectURL(file)
                        : undefined
                    }
                    alt={`Uploaded preview ${index}`}
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                      borderRadius: "8px",
                    }}
                  />
                  {/* Delete Icon Button */}
                  <IconButton
                    onClick={() => handleRemoveImage(index)}
                    sx={{
                      position: "absolute",
                      top: -5,
                      right: -5,
                      backgroundColor: "rgba(255, 255, 255, 0.7)",
                      "&:hover": {
                        backgroundColor: "rgba(255, 0, 0, 0.7)",
                      },
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </div>
              ))}
            </div>
          )}

          <label htmlFor={imageFiles.length > 0 ? "" : "new-image-upload"}>
            <Button
              variant="contained"
              onClick={() => {
                if (imageFiles.length > 0) {
                  handleImageUpload();
                } else {
                  document.getElementById("new-image-upload").click();
                }
              }}
              sx={{
                width: "100%",
                mt: 2,
                backgroundColor: "#007bff",
                "&:hover": {
                  backgroundColor: "#0056b3",
                },
                textTransform: "capitalize",
              }}
            >
              {imageFiles.length === 1
                ? "Upload Selected Image"
                : imageFiles.length > 1
                ? "Upload Selected Images"
                : "Upload New Image"}
            </Button>
          </label>

          <Typography variant="h6" sx={{ mt: 3 }} align="center">
            Select an existing image
          </Typography>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
              maxHeight: "200px",
              overflowY: "auto",
              justifyContent: "center",
              marginTop: 10,
            }}
          >
            {uploadedImages && uploadedImages.length > 0 ? (
              uploadedImages.map((url, index) => (
                <img
                  key={index}
                  src={url}
                  alt={`Uploaded preview ${index}`}
                  onClick={() => insertImageIntoEditor(url)}
                  style={{
                    cursor: "pointer",
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                    border: "2px solid transparent",
                    borderRadius: "8px",
                    transition: "border-color 0.2s",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.borderColor = "#007bff")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.borderColor = "transparent")
                  }
                />
              ))
            ) : (
              <Typography>No images available</Typography>
            )}
          </div>
        </Box>
      </Modal>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarData.snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }} // Positioning Snackbar
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarData.snackbarSeverity}
        >
          {snackbarData.snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default RichTextEditor;
